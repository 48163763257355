import * as React from 'react';
import { navigate } from 'gatsby'
import api from '../util/api';
import {
  Button, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography,
  CircularProgress, IconButton, InputAdornment, Avatar, Alert
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Popover } from 'antd';


import Background from '/images/411440x810.jpeg'
import Logo from '/images/logo/iseelogo1.png'
import Swal from 'sweetalert2'
import jwt_decode from "jwt-decode";
import './index.css';
import {
  validateRequire,
  validatePassword,
} from '../util/validate'


export default function SignUp(props) {

  const [user, setUser] = React.useState('')
  const [expanded, setExpanded] = React.useState(false)
  const [token, setToken] = React.useState(null);
  const [validate_field, setValidateField] = React.useState({ pass: [] })
  const [alert_err, setAlertErr] = React.useState('')
  const [onload, setOnLoad] = React.useState(false)
  const [user_data, setUserData] = React.useState({ pass: '' })
  let [validatePass, setValidatePass] = React.useState(validatePassword(user_data.pass))
  const [showPass, setShowPass] = React.useState({ pass: false })

  React.useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const _token = url.searchParams.get('token')
      if (!_token) return null
      var decoded = jwt_decode(_token);
      setUser(decoded.user)
      if (Date.now() > decoded.exp * 1000) {
        return setExpanded(true)
      } else {
        setToken(_token)
      }
    } catch (err) {

    }

  }, [])


  const onReset = async (data) => {
    try {
      console.log("token>> ", token)
      const res = await api.resetPassByEmail(data, token)
      setOnLoad(false)
      Swal.fire('', 'เปลี่ยนรหัสผ่านสำเร็จแล้ว', 'success')
      navigate("/", { replace: true })
    } catch (err) {
      let text_alert = ''
      if (Array.isArray(err)) {
        err.forEach((ele, idx) => {
          idx === err.length - 1 ? text_alert += ele : text_alert += (ele + ', ')
        })
      } else {
        text_alert = err
      }
      setOnLoad(false)
      setAlertErr(text_alert)
    }
  }


  const onValidate = (data) => {

    validate_field.pass = []
    validate_field.pass.push(
      validateRequire(data.pass),
      Object.keys(validatePassword(data.pass)).filter(x => !validatePassword(data.pass)[x].isvalid).length > 0 ? 'เงื่อนไขการตั้งรหัสผ่านไม่ผ่าน' : null
    )

    setValidateField({ ...validate_field })

    return validate_field

  }


  const handleSubmit = () => {
    let check_validate = false
    let register_validate = onValidate(user_data)
    for (let key in register_validate) {
      check_validate = register_validate[key].filter(item => item !== null).length > 0
      if (check_validate) break;
    }
    if (!check_validate) {
      setOnLoad(true)
      onReset(user_data)
    }
  };

  let contentPass = (
    <div>
      {Object.keys(validatePass).map(x => {
        let _data = validatePass[x]
        if (_data.isvalid) {
          return <p className='line-through'>- {_data.text}</p>
        } else {
          return <p className='text-red-500'>- {_data.text}</p>
        }
      })}
    </div>
  );

  if (expanded) {
    return (
      <div className='bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${Background})` }}>
        <div className='min-h-[100vh] flex justify-center xl:px-16 xl:pt-[100px] xl:pb-[100px]'>
          <div className="w-full xl:w-1/3 rounded-3xl shadow-2xl backdrop-opacity-10 backdrop-invert bg-white/90">
            <Box
              className='flex flex-col items-center py-32 px-8 xl:py-4 xl:px-16'
            >
              {
                props.location.state ?
                  <Avatar
                    className='my-4'
                    alt={props.location.state.profile.line.name}
                    src={props.location.state.profile.line.img}
                    sx={{ width: 150, height: 150 }} /> :
                  <img src={Logo} alt="" style={{ width: 200 }} />
              }

              <p className='text-xl'>Link หมดอายุการใช้งาน</p>
              <a className='text-lg' onClick={() => navigate("/", { replace: true })}>กลับหน้าหลัก</a>
            </Box>
          </div>
        </div>
      </div>
    )

  }

  return (
    <div className='bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${Background})` }}>
      <div className='min-h-[100vh] flex justify-center xl:px-16 xl:pt-[100px] xl:pb-[100px]'>
        <div className="h-[400xp] w-full lg:w-[600px] rounded-3xl shadow-2xl backdrop-opacity-10 backdrop-invert bg-white/90">
          <Box className='flex flex-col items-center py-32 px-8 xl:py-4 xl:px-16'>
            {
              props.location.state ?
                <Avatar
                  className='my-4'
                  alt={props.location.state.profile.line.name}
                  src={props.location.state.profile.line.img}
                  sx={{ width: 150, height: 150 }} /> :
                <img src={Logo} alt="" style={{ width: 200 }} />
            }

            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
              noValidate
              autoComplete="off">
              <p className='text-center text-3xl'>เปลี่ยนรหัสผ่านของคุณ</p>
              <div className='grid grid-cols-4 gap-2'>
                <TextField
                  disabled
                  className="col-span-4 bg-gray-200"
                  required
                  value={user}
                  label="user"
                  margin="normal"
                  id="user"
                  type={"user"}
                  name="user"
                />
                <Popover placement="bottom" title='เงื่อนไขการตั้งรหัสผ่าน' content={contentPass} trigger="focus">
                  <TextField
                    className="col-span-4 bg-gray-200"
                    onChange={(e) => {
                      setValidatePass({ ...validatePassword(e.target.value) })
                      setUserData({ ...user_data, pass: e.target.value })
                    }}
                    required
                    error={validate_field.pass.filter(item => item !== null).length > 0}
                    helperText={validate_field.pass.filter(item => item !== null).length > 0 ? validate_field.pass.filter(item => item !== null)[0] : ''}
                    margin="normal"
                    id="password"
                    type={showPass.pass ? 'text' : "password"}
                    name="password"
                    label="Password"
                    InputProps={{
                      maxLength: 50,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setShowPass({ ...showPass, pass: !showPass.pass }) }}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPass.pass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Popover>
              </div>
              {
                alert_err !== '' ?
                  <Alert variant="outlined" severity="error">
                    {alert_err}
                  </Alert>
                  : <></>
              }

              <Button
                disabled={onload}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
                variant="contained"
                startIcon={onload ? <CircularProgress size={16} color="inherit" /> : false}>
                {onload ? 'กำลังเปลี่ยนรหัสผ่าน' : 'เปลี่ยนรหัสผ่าน'}
              </Button>


            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}